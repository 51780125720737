<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="surveys"
            :items-per-page="5"
            class="elevation-1"
        >
            <template v-slot:item.title="{ item }">
                <router-link :to="{name: 'admin.surveys.show', params: {id: item.id}}">
                    {{ item.title }}
                </router-link>
            </template>
            <template v-slot:item.user="{ item }">
               <router-link :to="{name: 'admin.users.show', params: {id: item.user_id}}">
                   {{ item.user.name }}
               </router-link>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Survey from "@/models/survey";

    export default {
        name: "surveys-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                surveys: [],
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Title', value: 'title' },
                    { text: 'User', value: 'user' },
                    { text: 'Created at', value: 'created_at' },
                ],
            }
        },
        methods: {

        },
        async mounted() {
            this.loading = true
            this.surveys = await Survey.include(['user']).get()
            this.loading = false
        },
    }
</script>

<style scoped>

</style>
